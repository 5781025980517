import { begonia_post_form, nuwa_post_form, nuwa_post } from './http'
import store from '@/store/index'
import md5 from 'md5'

export const learnPackages = (packageId) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/coursePackageUser/learnPackages',
        data: {
            userId: userInfo.id,
            packageId,
        },
    })
}

export const getPackageInfo = (packageId) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/course/queryByPackage',
        data: {
            userId: userInfo.id,
            packageId: packageId,
        },
        loading: { status: false },
    })
}

export const getVideoPlayUrl = (data) => {
    const { userInfo } = store.state.user
    const md = md5(`${userInfo.token}_${userInfo.id}_${data.packageNo}_${data.courseNo}`)
    return begonia_post_form({
        path: '/live/getVideoPlayUrl',
        data: {
            userId: userInfo.id,
            md,
            ...data,
        },
    })
}

export const getPackageStage = (packageId) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/coursePackage/getStages',
        data: {
            userId: userInfo.id,
            coursePackageId: packageId,
        },
    })
}

export const getStageCourse = (packageId, stageId) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/course/queryByStage',
        data: {
            userId: userInfo.id,
            packageId,
            stageId,
            coursePackageId: packageId,
        },
        loading: { status: false },
    })
}

export const addUserAttend = (data) => {
    const { userInfo } = store.state.user
    return nuwa_post({
        path: '/course/addUserAttend',
        data: {
            userId: userInfo.id,
            ...data,
        },
        loading: {
            status: false,
        },
        isCatch: true,
    })
}

export const getPlayUrl = (data) => {
    const { userInfo } = store.state.user
    return begonia_post_form({
        path: '/live/getPlayUrl',
        data: {
            userId: userInfo.id,
            ...data,
        },
    })
}
// 评价
export const userEvaluation = function (data) {
    const { userInfo } = store.state.user
    return nuwa_post({
        path: '/course/evaluation',
        data: {
            userId: userInfo.id,
            ...data,
        },
    })
}

// 查询课件
export const getHasHandout = function (data) {
    const { userInfo } = store.state.user
    return nuwa_post({
        path: '/course/hasHandout',
        data: {
            userId: userInfo.id,
            ...data,
        },
    })
}
export const getHandout = function (data) {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/course/handout?ver=1',
        data: {
            userId: userInfo.id,
            ...data,
        },
        isCatch: true,
    })
}
export const getHandoutInfo = function (data) {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/course/handout/get',
        data: {
            userId: userInfo.id,
            ...data,
        },
        isCatch: true,
    })
}

export const getPackageCourses = (data) => {
    const { userInfo } = store.state.user
    return nuwa_post({
        path: '/coursePackage/courses',
        data: { userId: userInfo.id, ...data },
    })
}
